import React from 'react';
import { useIntl } from '@utils/localize';
import { StoriesSearchStats } from './styles';

const StoriesCategoryHeader = ({ categoryTitle }) => {
  const intl = useIntl();

  return (
    <StoriesSearchStats>
      <span>{intl.formatMessage({ id: 'searchResults.stories' })}: &#8220;{categoryTitle}&#8221;</span>
    </StoriesSearchStats>
  );
};

export default StoriesCategoryHeader;
